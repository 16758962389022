import "./financeportal.css";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Box } from '@material-ui/core';
import React, { useState, useEffect } from "react";
import {  Modal, Form , Dropdown , DropdownToggle , DropdownMenu} from 'react-bootstrap';
import Navbarfinbox from "../financePortal/navbarfinbox";
import ReactPaginate from 'react-paginate';
import { Button ,Accordion, Card,} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { baseUrlUser} from "../utils/common";
import { getAllClientIds ,getClientDatabyClientId ,deleteVendorbyId,getAllPointsinPool ,getClientforpoints ,UpdateClientPoints , downloadClientExcel,getAllVendorDatabyvendorId ,updateVendorData} from "../services/ProductsService";
import { Typography } from "antd";
import add  from "../assests/public/ADD.svg";
import minus  from "../assests/public/MINUS.svg";
import Select from 'react-select';
const CreditPointUsage = () => {
  const [vendorData, setVendorData] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [clientData, setClientData] = useState(null);
  const [ClientPoints, setClientPoints] = useState([]);
  const [loadingClients, setLoadingClients] = useState({});
  const [openAccordion, setOpenAccordion] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [transactionType, setTransactionType] = useState('Allocate');
  const [points, setPoints] = useState(50);
  const [client, setClient] = useState('');
  const [reason, setReason] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [totalBalancePoints, setTotalBalancePoints] = useState({});
  const fetchClientId = async () => {
    try {
      const response = await getAllClientIds();
      if (response.status === 200) {
        setClientId(response.data.sort());
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to fetch client data.',
        });
      }
    } catch (error) {
      console.error('Error fetching client IDs:', error);
    }
  };
  const fetchTotalBalancePoints = async () => {
    try {
      const response = await getAllPointsinPool();
      if (response.status === 200) {
        setTotalBalancePoints(response.data);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to fetch total balance points.",
        });
      }
    } catch (error) {
      console.error("Error fetching total balance points:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while fetching total balance points.",
      });
    }
  };
  const sortData = (data) => {
    return [...data].sort((a, b) => {
      if (sortField) {
        const fieldA = a[sortField];
        const fieldB = b[sortField];
        if (sortOrder === 'asc') {
          return fieldA > fieldB ? 1 : -1;
        } else {
          return fieldA < fieldB ? 1 : -1;
        }
      }
      return 0;
    });
  };
  const handleSortChange = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const sortedClientPoints = sortData(isFilterApplied ? filteredData : ClientPoints);
  const fetchClientforpoints = async () => {
    try {
      debugger;
      const response = await getClientforpoints();
      if (response.status === 200) {
        setClientPoints(response.data.sort((a, b) => a.clientId.localeCompare(b.clientId, undefined, { sensitivity: 'case' })));
        const filtered = response.data.filter(client => client.clientId === selectedClientId);
        console.log('Filtered Data:', filtered); // Debugging statement
        setFilteredData(filtered);
        console.log(response.data , "ClientPoints")
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to fetch client data.',
        });
      }
    } catch (error) {
      console.error('Error fetching client IDs:', error);
    }
  };
  const handleOpenModal = (client) => {
    if (client && client.clientId) {
      // If a valid client object is provided, set the client and show the modal
      setClient(client.clientId);
      setShowModal(true);
    } else if (client.length === 0 && selectedClientId) {
      // If no client is selected but selectedClientId is set, use it
      setClient(selectedClientId);
      setShowModal(true);
    } else {
      // If neither condition is met, show an error
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Client ID is blank. Please select a Client ID.',
      });
    }
  };
  

  const handleCloseModal = () => {
    // Reset form state
    setTransactionType('Allocate'); // or the default value
    setPoints('');
    setReason('');
  
    // Close the modal
    setShowModal(false);
  };
  
  const fetchDatabyclientId = async (clientId) => {
    if (openAccordion === clientId) {
      setOpenAccordion(null); // Close if already open
      return;
    }
    setLoadingClients(prevState => ({ ...prevState, [clientId]: true }));
    try {
      const response = await getClientDatabyClientId(clientId);
      if (response.status === 200) {
        const fetchedData = response.data;
        setOpenAccordion(clientId);
        setClientData(fetchedData);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to fetch client data.',
        });
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
    } finally {
      setLoadingClients(prevState => ({ ...prevState, [clientId]: false }));
    }
  };

  const handleDownload = async (clientId) => {
    if (!clientId) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Client ID is required.',
      });
      return;
    }
    try {
      const response = await downloadClientExcel(clientId);
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Downloaded',
          text: 'File downloaded successfully.',
        });
      } else {
        console.error('Error fetching client data:');
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };

  useEffect(() => {
    document.title = 'Client Point Usage';
    fetchClientId();
    fetchClientforpoints();
    fetchTotalBalancePoints();
  }, []);


  const ClientIdToDisplay = sortedClientPoints.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const handlePageChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    setPageNumber(selectedPage);
  };

  const totalPages = Math.ceil((isFilterApplied ? filteredData.length : ClientPoints.length) / itemsPerPage);
  const pageOptions = Array.from({ length: totalPages }, (_, index) => index);
  
  const handleSaveChanges = async () => {
    try {
      debugger;
      const data = {
        clientId: client,
        transactionType : transactionType,
        points: points,
        reason
      };

      const response = await UpdateClientPoints(data);

      if (response.status === 200) {
        fetchClientforpoints();
        fetchTotalBalancePoints();
        handleCloseModal();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: "Points allocatioin successful."
        }); 
        fetchDatabyclientId(client);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.points || response.data.reason || response.data ||'Failed to save changes.',
        });
      }
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };
  const handleApplyFilter = () => {
    if (selectedClientId) {
      const filtered = ClientPoints.filter(client => client.clientId === selectedClientId);
      console.log('Filtered Data:', filtered); // Debugging statement
      setFilteredData(filtered);
      setIsFilterApplied(true);
      setPageNumber(0); // Reset page number
    } else {
      setFilteredData([]);
      setIsFilterApplied(false);
      setPageNumber(0); // Reset page number
    }
  };
  
  

  const handleResetFilter = () => {
    setSelectedClientId('');
    setIsFilterApplied(false);
    setFilteredData([]);
    setPageNumber(0); // Reset page number
  };
  
  const handleChange = (e) => {
    const selectedValue = Number(e.target.value);
    console.log('Selected Points:', selectedValue); // Debugging statement
    setPoints(selectedValue);
  };
  return (
    <div className="box5">
      <Navbarfinbox />
      <div className="p-4">
        <div className="row">
          <div className="col-6 mt-4 custom-font-20">Client Points Usage</div>
          <div className="col-6 mt-4 text-right">
            <Button className="tz-primary-btn grey btn btn-secondary mr-2" onClick={() => handleDownload(selectedClientId)}>Download Excel</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1 mt-2 custom-font-16">Filter by Client ID</div>
        
          <div className="col-md-2 mt-2">
            <Form.Group className="col-md-12 mb-3" controlId="formClientIds">
            <Select
 isClearable
 options={
  clientId && clientId.length > 0
    ? clientId.map(client => ({ value: client, label: client }))
    : [{ value: '', label: 'No Client IDs Available', isDisabled: true }]
}
 value={selectedClientId ? { value: selectedClientId, label: selectedClientId } : null}
 onChange={(option) => setSelectedClientId(option ? option.value : '')}
 placeholder="Select or Enter Client ID"
>
</Select>

            </Form.Group>
          </div>
          <div className="col-md-1 text-right mt-2">
            <Form.Group controlId="paginationDropdown">
              <Form.Control as="select" value={pageNumber}   disabled={isFilterApplied}  onChange={handlePageChange}>
                {pageOptions.map(page => (
                  <option key={page} value={page}>
                    Page {page + 1}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-2 mt-2">
          <div style = {{border :"1px solid #ddd" ,
           borderRadius: '4px', 
    padding: '6px', 
    fontSize: '15px', 
    textAlign: 'center', 
    backgroundColor: '#fff',
}} >Total Balance Points : {totalBalancePoints.totalTreziPoints}
            </div> 
          </div>
          <div className="col-md-1 mt-2">
            <Dropdown>
              <Dropdown.Toggle variant="tz-primary" id="dropdown-basic">
                Sort by
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSortChange('totalPoints')}>
                  Total Points ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange('balancePoints')}>
                  Balance Points ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange('usedPoints')}>
                  Used Points ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        
          <div className="col-md-5 mt-2 text-right">
            <Button className="tz-primary-btn btn btn-secondary mr-2" onClick={handleApplyFilter} >
              Apply Filter
            </Button>
            <Button className="btn btn-secondary mr-2" onClick={handleResetFilter} >
             Reset
            </Button>
            
          </div>
        </div>
        <div>

  {(isFilterApplied ? filteredData : ClientIdToDisplay).map((client, index) => (
    <div className="accordion mt-2" id={`accordion-${client.clientId}`} key={client.clientId}>
      {isFilterApplied && filteredData.length === 0 ? (
        <p>No client found.</p>
      ) : (
        <div className="accordion-item">
          <h2 className="accordion-header" id={`heading-${client.clientId}`}>
            <button
              className="accordion-button"
              type="button"
              onClick={() => fetchDatabyclientId(client.clientId)}
              aria-expanded={openAccordion === client.clientId}
              aria-controls={`collapse-${client.clientId}`}
            >
              {openAccordion === client.clientId ? (
                <img src={minus} alt="Minus" />
              ) : (
                <img src={add} alt="Plus" />
              )}
              <span className="ml-2">
                {client.clientId}{' '}
                <span className="ml-4 ">
                  <span>Total Points</span> <span>{client.totalPoints}</span>
                  <span className="divider"></span>
                  <span>Used Points</span> <span>{client.usedPoints}</span>
                  <span className="divider"></span>
                  <span>Balance Points</span> <span>{client.balancePoints}</span>
                </span>
              </span>
              <span className="ml-auto d-flex justify-content-between">
                <Button className="btn btn-primary mr-2" onClick={() => handleOpenModal(client)}>
                  Allocate/De-allocate Points
                </Button>
              </span>
            </button>
          </h2>
          <div
            id={`collapse-${client.clientId}`}
            className={`accordion-collapse collapse ${openAccordion === client.clientId ? 'show' : ''}`}
            aria-labelledby={`heading-${client.clientId}`}
            data-bs-parent={`#accordion-${client.clientId}`}
          >
            <div className="accordion-body">
              {loadingClients[client.clientId] ? (
                <p>Loading...</p>
              ) : clientData && openAccordion === client.clientId ? (
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div></div>
                  </div>
                  <div>
                    {clientData && clientData.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                          <th>Transaction Type</th>
                            <th>Transaction ID</th>
                            <th>Date & Time</th>
                            <th>Services</th>
                            <th>Points</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientData.map((transaction, index) => (
                            <tr key={index}>
                                                            <td>{transaction.transactionType}</td>
                              <td>{transaction.transactionId}</td>
                              <td>{new Date(transaction.date).toLocaleString()}</td>
                              <td>{transaction.treziService}</td>
                              <td>{transaction.points}</td>
                              <td>{transaction.remarks}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No transactions found.</p>
                    )}
                  </div>
                </Card.Body>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  ))}

</div>

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Allocate/De-allocate Points</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="transactionType">
                <Form.Label>Transaction Type</Form.Label>
                <Form.Control as="select" value={transactionType} onChange={(e) => {
  console.log('Selected Transaction Type:', e.target.value);  // Debugging statement
  setTransactionType(e.target.value);
}}>
  <option value="Allocate">Allocate</option>
  <option value="De-Allocate">De-Allocate</option>
</Form.Control>


              </Form.Group>
          <Form.Group controlId="points">
      <Form.Label>Points</Form.Label>
      <Form.Control
        as="select"
        value={points}
        onChange={handleChange}
        onClick={handleChange}
        placeholder="Select Points"
      >
         <option >Select Points</option>
       <option value={50}>50 Points</option>
        <option value={100}>100 Points</option>
        <option value={150}>150 Points</option>
        <option value={200}>200 Points</option>
        <option value={250}>250 Points</option>
        <option value={300}>300 Points</option>
        <option value={350}>350 Points</option>
        <option value={400}>400 Points</option>
        <option value={450}>450 Points</option>
        <option value={500}>500 Points</option>
      </Form.Control>
    </Form.Group>

              <Form.Group controlId="reason">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Specify your reason....."
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="tz-primary-btn reverse"  onClick={handleCloseModal}>
              Close
            </Button>
            <Button type='submit' variant="secondary" className="tz-primary-btn" onClick={handleSaveChanges}>
             Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CreditPointUsage;


