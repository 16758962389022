import { baseUrl, interceptor , baseUrlUser,baseUrlDashboard , baseUrlVendorManage} from "../utils/common";
import axios from "axios";
const url = baseUrl;
const urlUser = baseUrlUser;

export const viewProductByPublicId = async (productId) => {
  try {
    const response = await axios.get(`${url}products/public/${productId}`);
    return response;
  } catch (error) {
    // Handle the error here
    return error.response; // You can choose to re-throw the error or handle it differently
  }
};

const axiosInstance = axios.create();
interceptor(axiosInstance);

export const fetchRole = async () => {
  const response = await axiosInstance
    .get(`${urlUser}users/getrole`)
    .then((response) => {
      localStorage.setItem('role', JSON.stringify(response.data));
      return response.data;
    });
  return response;
};

export const getProducts = async (currentPage) => {
  const pageNumber = currentPage;
  debugger;
  const response = await axiosInstance.get(`${url}products/web/paginated?page=${pageNumber}&pageSize=12`).then((response) => {
    return response;
  });
  return response;
};

export const getProductById = async (productId) => {
  debugger;
  const response = await axiosInstance
    .get(`${url}products/${productId}`)
    .then((response) => {
      return response.data;
    });
  return response;
};

export const getProductByIdforCustomer = async (productId) => {
  debugger;
  const response = await axiosInstance
    .get(`${url}products/csm/${productId}`)
    .then((response) => {
      return response;
    });
  return response;
};
export const updateStatus = async (id, label) => {
  try {
    debugger;
    const response = await axiosInstance.post(`${url}products/status`, {
      productId: `${id}`,
      status: `${label}`,
    });
    return response; // Return the response data
  } catch (error) {
    console.error('Error updating status:', error);
    return error.response; // Return the response data// Rethrow the error to handle it in the calling code
  }
};

export const updateStatusBatch = async (statusBatch) => {
try {
    debugger;
    const response = await axiosInstance.post(
      `${url}products/status/batch`,
      statusBatch
    );
    return response.status;
  } catch (error) {
    console.error('Error updating status:', error);
    return error.response; // Return the response data// Rethrow the error to handle it in the calling code
  }
};

export const updateProduct = async (data) => {
  debugger;
  const response = await axiosInstance.post(`${url}products/update`, data);
  return response;
};

export const deleteProduct = async (id) => {
  const response = await axiosInstance.delete(`${url}products/${id[0]}`);
  return response.data;
};

// export const deleteProductBatch = async (batchOfProductIds) => {
//   debugger;
//   const response = await axiosInstance.delete(`${url}products`, batchOfProductIds);
//   return response.data;
// };
export const deleteProductBatch = async (batchOfProductIds) => {
  try {
      debugger;
      const response = await axiosInstance.delete(
        `${url}products`,
      { data: batchOfProductIds}
      );
      return response;
    } catch (error) {
      console.error('Error Deleting Products', error);
      return error; // Return the response data// Rethrow the error to handle it in the calling code
    }
  };

export const increaseViewCount = async (id, code, label) => {
  const response = await axiosInstance.post(
    `${url}product-analytics/views?productId=${id}`,
    // {
    //   productId: `${id}`,
    //   status: { code: `${code}`, label: `${label}` },
    // }
  );
  return response;
};

export const increaseShareCount = async (id, status) => {
  const response = await axiosInstance.post(
    `${url}product-analytics/shares?productId=${id}`,
    // {
    //   productId: `${id}`,
    //   status: { status: `${status}` },
    // }
  );
  return response;
};

export const getViewCount = async (id,) => {
  const response = await axiosInstance.get(
    `${url}product-analytics/views?productId=${id}`,
    // {
    //   productId: `${id}`,
    //   status: { code: code, label: label },
    // }
  );
  return response.data;
};

export const getShareCount = async (id,) => {
  const response = await axiosInstance.get(
    `${url}product-analytics/shares?productId=${id}`,
    // {
    //   productId: `${id}`,
    //   status: { code: code, label: label },
    // }
  );
  return response.data;
};

export const createAttribute = async (attributeData) => {
  const response = await axiosInstance.post(`${url}attributes/add`, attributeData);
  return response.data;
};

export const getAllAttributes = async () => {
  try {
    const response = await axiosInstance.get(`${url}attributes/all`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    throw error;
  }
};
export const getSingleAttribute = async (label) => {
  try {
    const response = await axiosInstance.get(`${url}attributes/get`, {
      params: {
        label: label
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error retrieving attribute:', error);
    throw error;
  }
};

export const updateProductAttribute = async (oldName, newName, oldLabel, newLabel, newVariableType) => {
  try {
    debugger;
    const response = await axiosInstance.put(`${url}attributes/update`, {
      oldName: oldName,
      newName: newName,
      oldLabel: oldLabel,
      newLabel: newLabel,
      newVariableType: newVariableType,
    });
    return response.data;
  } catch (error) {
    // Handle error if needed
    throw error;
  }
};


export const deleteAttribute = async (label) => {
  try {
    const response = await axiosInstance.delete(`${url}attributes/delete/${label}`);
    return response;
  } catch (error) {
    console.error('Error deleting attribute:', error);
    return error;
  }
};
export const createCluster = async (clusterData) => {
  debugger;
  const response = await axiosInstance.post(`${url}clusters/add`, clusterData);
  return response.data;
};


export const getAllClusters = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}clusters/all`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    throw error;
  }
};

export const getSingleCluster = async (label) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}clusters/get`, {
      params: {
        label: label
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error retrieving attribute:', error);
    throw error;
  }
};

export const updateProductCluster = async (oldName, newName, oldLabel, newLabel, attributeNames) => {
  try {
    debugger;
    const response = await axiosInstance.put(`${url}clusters/update`, {
      oldName,
      newName,
      oldLabel,
      newLabel,
      attributeNames,
    });
    return response;
  } catch (error) {
    // Handle error if needed
    return error.response;
  }
};


export const deleteClusters = async (label) => {
  try {
    debugger;
    const response = await axiosInstance.delete(`${url}clusters/delete/${label}`);
    return response;
  } catch (error) {
    console.error('Error deleting attribute:', error);
    return error;
  }
};

export const CreateCategory = async (categoryData) => {
  debugger;
  const response = await axiosInstance.post(`${url}categories/add`, categoryData);
  return response.status;
};

export const getAllpublishedCategories = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/published/all`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving category:', error);
    throw error;
  }
};

export const getAllpublishedsuperCategories = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/published/super`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving category:', error);
    throw error;
  }
};

export const getAllCustomerCategories = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/customer/all`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    return error;
  }
};
export const getAllPublishedCustomerCategories = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/customer/published/products`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    return error;
  }
};
export const getAllCustomerAttributes = async (CategoryName) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/customer/attributes?label=${CategoryName}`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    throw error;
  }
};
export const deleteCustomerCategory = async (label) => {
  try {
    debugger;
    const response = await axiosInstance.delete(`${url}categories/customer/delete/${label}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting Customer Category:', error);
    throw error;
  }
};


export const getSinglecustomerCategory = async (label) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/customer/get`, {
      params: {
        label: label
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error retrieving Customer Category:', error);
    throw error;
  }
};

export const updateCustomerCategory = async (name , clusterNames) => {
  try {
    debugger;
    const response = await axiosInstance.post(`${url}categories/customer/update`, {
      name , 
      clusterNames,
    });
    return response.data;
  } catch (error) {
    // Handle error if needed
    throw error;
  }
};

export const moveCategory = async (moveCategoryData) => {
  debugger;
  const response = await axiosInstance.post(`${url}categories/move`, moveCategoryData);
  return response.data;
};


export const createProduct3d = async (formData) => {
  try {
    debugger;
    const response = await axiosInstance.post(`${url}products`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error while creating product:', error);
    return error;
  }
};
export const createProduct2d = async (formData) => {
  try {
    debugger;
    const response = await axiosInstance.post(`${url}products/2d`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error while creating product:', error);
    return error;
  }
};
export const customerSuccessProductUpload = async (formData) => {
  try {
    debugger;
    const response = await axiosInstance.post(`${url}products/add/cs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error while creating product:', error);
    throw error;
  }
};
export const customerSuccessProductUpload2d = async (formData) => {
  try {
    debugger;
    const response = await axiosInstance.post(`${url}products/2d/cs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error while creating product:', error);
    throw error;
  }
};
export const getAllCustomerSucessCategory = async (clientId) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/customer/csm?clientId=${clientId}`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    throw error;
  }
};

export const getAllCustomerSuccessCategories = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}products/cs`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    throw error;
  }
};
export const getAllFinanceData = async (pageSize) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${urlUser}payments/finance/all?page=1&pageSize=${pageSize}`);
    return response;
  } catch (error) {
    console.error('Error retrieving finance data:', error);
    return error;
  }
};
export const getFinanceDataBasedOnStatus = async (status,pageSize) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${urlUser}payments/finance?status=${status}&page=1&pageSize=${pageSize}`);
    return response;
  } catch (error) {
    console.error('Error retrieving finance data:', error);
    return error;
  }
};
export const updateProductSingleProductforcsm = async (formData) => {
  try {
    debugger;
    const response = await axiosInstance.put(`${url}products/update/cs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Request failed with status code:", error.response.status);
      console.error("Response data:", error.response.data);
    } else if (error.request) {
      console.error("No response received from the server:", error.request);
    } else {
      console.error("Error while processing the request:", error.message);
    }
    throw error; 
  }
};
export const updateProductSingleProductforcsm2d = async (formData) => {
  try {
    debugger;
    const response = await axiosInstance.put(`${url}products/update/2d/cs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Request failed with status code:", error.response.status);
      console.error("Response data:", error.response.data);
    } else if (error.request) {
      console.error("No response received from the server:", error.request);
    } else {
      console.error("Error while processing the request:", error.message);
    }
    throw error; 
  }
};
export const getAllPendingCustomerSuccessCategories = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/pending`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    return error.response.data;
  }
};
export const getAllApproveCustomerSuccessCategories = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/approved`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    return error.response.data;
  }
};
export const getAllRejectCustomerSuccessCategories = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/rejected`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    return error.response.data;
  }
};
export const approvePendingCategory = async (label) => {
  try {
    debugger;
    const response = await axiosInstance.put(`${url}categories/approve?label=${label}`);
    return response.data;
  } catch (error) {
    console.error('Error approvinf Request:', error);
    throw error;
  }
};

export const rejectPendingCategory = async (label , rejectionReason) => {
  try {
    debugger;
    const response = await axiosInstance.put(`${url}categories/reject`, {
      label :label , 
      reason :rejectionReason,
    });
    return response.status;
  } catch (error) {
    console.error('Error approving Request:', error);
    throw error;
  }
};

export const downloadProductforcsm = async (productId, fileType) => {
  try {
    debugger;
    const url = baseUrl + 'products/download/' + productId + '/' + fileType;
    const response = await axiosInstance.get(url, { responseType: 'blob' });
     if (response.status === 200) {
      const contentDispositionHeader = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'downloaded_file.xlsx';

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const urlObject = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = fileName; // Set the extracted file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(urlObject);
      return true;
    }  else {
      console.error('Error downloading file. Response status:', response.status);
      throw new Error('Download failed');
    }
  } catch (error) {
    console.error('Error downloading file', error);
    throw new Error('Download failed');
  }
};
// export const downloadProductforcsm = async (productId, fileType) => {
//   try {
//     debugger;
//     const response = await axiosInstance.get(`${url}products/download/${productId}/${fileType}`);
//     return response;
//   } catch (error) {
//     console.error('Error downloading file', error);
//     throw new Error('Download failed');
//   }
// };
export const downloadProductXlsxFile = async (categoryName) => {
  try {
    const url = baseUrl + 'excel/get?' + 'categoryLabel' + '=' + categoryName;
    const response = await axiosInstance.get(url, { responseType: 'blob' });

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const urlObject = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject; // Corrected URL assignment
      const contentDispositionHeader = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'downloaded_file.xlsx';
      a.download = fileName ; // Set the desired file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(urlObject);
      return true;
    } else {
      console.error('Error downloading file. Response status:', response.status);
      throw new Error('Download failed');
    }
  } catch (error) {
    console.error('Error downloading file', error);
    throw new Error('Download failed');
  }
};

export const uploadTemplatefiles = async (formData,label,) => {
  try {
    debugger;
    const response = await axiosInstance.post(
      `${url}excel/upload/background?categoryLabel=${label}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error('Error approving Request:', error);
    throw error;
  }
};
export const getClientCsmCategories = async (clientId) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}categories/customer/csm?clientId=${clientId}`);
    return response;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    return error;
  }
};

export const downloadProductxslxfileforCsm = async (categoryName, clientId) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}excel/get/cs?categoryLabel=${categoryName}&clientId=${clientId}`, {
      responseType: 'blob', // Set the response type to 'blob' to receive binary data
    });

    if (response.status === 200) {
      // Extract file name from the content-disposition header
      const contentDispositionHeader = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'downloaded_file.xlsx';

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const urlObject = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = fileName; // Set the extracted file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(urlObject);
    } else {
      console.error('Error downloading file. Response status:', response.status);
      throw new Error('Download failed');
    }
  } catch (error) {
    console.error('Error downloading file', error);
    throw new Error('Download failed');
  }
};

export const uploadTemplatefilesforCsm = async (formData,label,clientId) => {
  try {
    debugger;
    const response = await axiosInstance.post(
      `${url}excel/upload/background/cs?categoryLabel=${label}&clientId=${clientId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error('Error approving Request:', error);
    throw error;
  }
};
export const submitProfilePicture = async (profilePic) => {
  try {
    debugger;
    const formData = new FormData();
    formData.append('profilePic', profilePic);
    const response = await axiosInstance.post(`${baseUrlUser}users/profile/pics`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
   return response;
  } catch (error) {
    console.error('Error Submitting pic', error);
    throw error;
  }
};

export const submitBrandLogo = async (brandLogo) => {
  try {
    debugger;
    const formData = new FormData();
    formData.append('brandLogo', brandLogo);
      const response = await axiosInstance.post(`${url}organizations/logos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.error('Error Submitting logo', error);
    throw error;
  }
};

export const submitShortcode = async (organizationIdentifier) => {
  try {
    const requestData = {
      organizationIdentifier: organizationIdentifier,
    };
   const response = await axiosInstance.post(`${url}organizations/identifiers`, requestData);
    return response;
  } catch (error) {
    console.error('Error Submitting identifiers', error);
    throw error;
  }
};

export const getBrandLogo = async () => {
  try {
    debugger;
      const response = await axiosInstance.get(`${url}organizations/logos`);
    return response;
  } catch (error) {
    console.error('Error retrieving customer category:', error);
    throw error;
  }
};

export const getShortcode = async () => {
  try {
debugger;
   const response = await axiosInstance.get(`${url}organizations/identifiers`);
    return response;
  } catch (error) {
    console.error('Error Submitting identifiers', error);
    throw error;
  }
};
export const getProfilePicture = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrlUser}users/profile/pics`);
   return response;
  } catch (error) {
    console.error('Error Submitting pic', error);
    throw error;
  }
};
export const downloadProductqrcode= async (publicUrl) => {
  try {
    debugger;
    const requestData = {
      publicUrl: publicUrl,
    };
    const response = await axiosInstance.post(`${url}products/qrcode` , requestData);
    return response;
  } catch (error) {
    console.error('Error downloading file', error);
    throw new Error('Download failed');
  }
};
export const updateProductforSingleProduct= async (formData) => {
  try {
    debugger;
    const response = await axiosInstance.put(`${url}products/update/cs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Request failed with status code:", error.response.status);
      console.error("Response data:", error.response.data);
    } else if (error.request) {
      console.error("No response received from the server:", error.request);
    } else {
      console.error("Error while processing the request:", error.message);
    }
    throw error; 
  }
};
export const updateProductforthumbnails = async (formData) => {
  debugger;
  const response = await axiosInstance.put(`${url}products/update/customer`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};
export const getInventoryLog = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrl}bulk/upload/logs`);
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    throw error;
  }
};

export const getProductbycustomerCategory = async (label) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${url}products/published/category?label=${label}`, {
    });
    return response;
  } catch (error) {
    console.error('Error retrieving Customer Category:', error);
    return error;
  }
};
export const createFlipbook = async (flipbookData) => {
  try {
    const response = await axiosInstance.post(`${url}flip/books/dynamic`, flipbookData);
    return response; // Assuming the response contains the created flipbook data
  } catch (error) {
    console.error('Error creating flipbook:', error);
    throw error;
  }
};
export const getAllflipbookTemplates = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrl}flip/books`);
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    throw error;
  }
};
export const deleteCustomerTemplate = async (flipBookIdentifier) => {
  try {
    debugger;
    const response = await axiosInstance.delete(`${url}flip/books/${flipBookIdentifier}`);
    return response;
  } catch (error) {
    console.error('Error deleting Customer Category:', error);
    throw error;
  }
};
export const updateFlipbookShareCount = async (flipBookIdentifier) => {
  debugger;
  const response = await axiosInstance.put(
    `${url}flip/books/share/count/${flipBookIdentifier}`,
  );
  return response;
};
export const downloadTemplateqrcode= async (publicUrl) => {
  try {
    debugger;
    const requestData = {
      publicUrl: publicUrl,
    };
    const response = await axiosInstance.post(`${url}products/qrcode` , requestData);
    return response;
  } catch (error) {
    console.error('Error downloading file', error);
    throw new Error('Download failed');
  }
};

export const getFlipbookByFlipbookIdentifier = async (flipBookIdentifier) => {
  debugger;
  const response = await axiosInstance.get(
    `${url}flip/books/${flipBookIdentifier}`,
  );
  return response;
};

export const getTemplateByTemplateIdentifier = async (TemplateIdentifier) => {
  debugger;
  const response = await axiosInstance.get(
    `${url}templates/${TemplateIdentifier}`,
  );
  return response;
};
export const getAllTemplates = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrl}templates`);
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    throw error;
  }
};
export const getProductsByIdForflipbook = async (productIdfetchedfromdata) => {
  debugger;
  const response = await axiosInstance.get(
    `${url}flip/books/products/dynamic/${productIdfetchedfromdata}`,
  );
  return response;
};
export const getTemplateforTemplatepreview = async (TemplateIdentifier) => {
  debugger;
  const response = await axiosInstance.get(
    `${url}templates/previews/${TemplateIdentifier}`,
  );
  return response;
};
export const updateflipbookStatus = async (id, status) => {
  try {
    debugger;
    const response = await axiosInstance.put(`${url}flip/books/status`, {
      flipBookIdentifier: `${id}`,
      status: `${status}`,
    });
    return response; // Return the response data
  } catch (error) {
    console.error('Error updating status:', error);
    return error.response; // Return the response data// Rethrow the error to handle it in the calling code
  }
};
export const getOrderManagement = async (status) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrl}orders/web?status=${status}`,
     {
      status: `${status}`,
    }
    );
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    throw error;
  }
};

export const getOrderAnalytics = async (status) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrl}orders/analytics` );
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    throw error;
  }
};

export const getCartValue = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}carts/items/count` );
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    throw error;
  }
};

export const AddToCartItems = async (productId ,color ) => {
  try {
    const requestData = {
      productId: productId,
      color:color
    };
   const response = await axiosInstance.post(`${url}carts/items`, requestData);
    return response;
  } catch (error) {
    console.error('Error Submitting identifiers', error);
    throw error;
  }
};

export const getCartItemsWithAmount = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrl}carts/items/calculate` );
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    return error;
  }
};
export const getCartItems = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrl}carts/items` );
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    throw error;
  }
};
export const OderStatusAccepted = async (orderId ,estimatedDispatchDate ) => {
  try {
    const requestData = {
      orderId: orderId,
      estimatedDispatchDate :estimatedDispatchDate
    };
   const response = await axiosInstance.put(`${url}orders/accept`, requestData);
    return response;
  } catch (error) {
    console.error('Error Submitting accept', error);
    throw error;
  }
};
export const OderStatusDispatch = async (orderId ,estimatedDeliveryDate ) => {
  try {
    const requestData = {
      orderId: orderId,
      estimatedDeliveryDate :estimatedDeliveryDate
    };
   const response = await axiosInstance.put(`${url}orders/dispatch`, requestData);
    return response;
  } catch (error) {
    console.error('Error Submitting dispatch', error);
    throw error;
  }
};
export const OderStatusDeliver = async (orderId  ) => {
  try {
    const requestData = {
      orderId: orderId,
    };
   const response = await axiosInstance.put(`${url}orders/deliver`, requestData);
    return response;
  } catch (error) {
    console.error('Error Submitting deliver', error);
    throw error;
  }
};
export const OderStatusRejected = async (orderId ,rejectionReason  ) => {
  try {
    const requestData = {
      orderId: orderId,
      rejectionReason : rejectionReason
    };
   const response = await axiosInstance.put(`${url}orders/reject`, requestData);
    return response;
  } catch (error) {
    console.error('Error Submitting reject', error);
    throw error;
  }
};
export const RemoveProducts = async (productId, color) => {
  debugger;
  try {
    const response = await axiosInstance.delete(`${url}carts/items`, {
      data: {
        productId: productId,
        color: color
      }
    });
    return response;
  } catch (error) {
    console.error('Error deleting products:', error);
    return error;
  }
};

export const PlaceOrder = async () => {
  try {
   const response = await axiosInstance.post(`${url}orders`);
    return response;
  } catch (error) {
    console.error('Error Placing order', error);
    throw error;
  }
};
export const UpdateandAddaddress = async (addressData) => {
  try {
    const response = await axiosInstance.put(`${url}addresses`, addressData);
    return response;
  } catch (error) {
    console.error('Error Submitting deliver', error);
    throw error;
  }
};


export const UpdateQuantityIncart = async ( productId, color , quantity ) => {
  try {
    debugger;
    const requestData = {
      productId: productId,
      color : color,
      quantity : quantity
    };
   const response = await axiosInstance.put(`${url}carts/items/quantity` , requestData);
    return response;
  } catch (error) {
    console.error('Error Submitting deliver', error);
    throw error;
  }
};

export const getAddressforcartItem = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}addresses` );
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    throw error;
  }
};

export const getColorexists = async (productId) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}products/colors/${productId}`
    );
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    return error;
  }
};
export const getColor = async (productId) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}products/colors/fetch/${productId}`
    );
   return response;
  } catch (error) {
    console.error('Error getting data', error);
    return error;
  }
};

export const AddColorforAndroid = async (productId, arData) => {
  try {
    debugger;
    const formData = new FormData();
    formData.append('productId', productId);
    Object.entries(arData).forEach(([colorKey, file]) => {
      formData.append(colorKey, file);
    });

    const response = await axiosInstance.post(`${url}products/images/android`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error) {
    console.error('Error Submitting identifiers', error);
    throw error;
  }
};
export const AddColorFileforIos = async (productId, arData) => {
  try {
    const formData = new FormData();
    formData.append('productId', productId);
debugger;
Object.entries(arData).forEach(([colorKey, file]) => {
  formData.append(colorKey, file);
});

    const response = await axiosInstance.post(`${url}products/images/ios`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.error('Error Submitting identifiers', error);
    throw error;
  }
};
export const AddGenAi = async (userId, clientId) => {
  try {
    const requestData = {
      product: "Showcase",
      userId: userId,
      clientId: clientId
    };
    const headers = {
      'x-api-key': 'KfW67z@QyN!vT#8oE*pG9Lm&1l#$^&$&$%^&$%nkjlfhgo@%$@jIuU2cRAbCdEfGhIjK$^%$^&$lMnOpQ^rStUvWxYz0123456789', // Replace with your actual API key
      'Content-Type': 'application/json'
    };
    const response = await axios.post(`${baseUrlDashboard}data/gen/ai/counts`, requestData, {
      headers: headers
    });
    return response;
  } catch (error) {
    console.error('Error Submitting deliver', error);
    throw error;
  }
};

export const getCategoriesForfilter = async () => {
  debugger;
  const response = await axiosInstance.get(
    `${url}categories/customer/list`,
  );
  return response;
};

export const ProductListOnthebaseOfcategories = async (Categoryname , page , pageSize) => {
  try {
    const requestData = {
      categoryNames: Categoryname,
      page : page,
      pageSize :pageSize
    };
   const response = await axiosInstance.post(`${url}products/categories/web/paginated`, requestData);
    return response;
  } catch (error) {
    console.error('Error Submitting identifiers', error);
    throw error;
  }
};
export const CreateVendorData  = async (newVendor) => {
  try{
  debugger;
  const response = await axiosInstance.post(`${baseUrlVendorManage}vendors`, newVendor );
  return response;
 } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};
export const getAllVendorData = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}vendors/all`);
    return response;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error;
  }
};
export const getAllVendorDatabyvendorId = async (VendorId) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}vendors/${VendorId}`);
    return response;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error;
  }
};
export const updateVendorData = async ( Vendordata) => {
  try {
    debugger;
    // const requestData = {
    //   productId: productId,
    //   color : color,
    //   quantity : quantity
    // };
   const response = await axiosInstance.put(`${baseUrlVendorManage}vendors` , Vendordata );
    return response;
  } catch (error) {
    console.error('Error Submitting deliver', error);
    return error.response;
  }
};

export const deleteVendorbyId = async (VendorId) => {
  try {
    debugger;
    const response = await axiosInstance.delete(`${baseUrlVendorManage}vendors/${VendorId}`);
    return response;
  } catch (error) {
    console.error('Error deleting Vendor:', error);
    return error.response;
  }
};
export const AddPayment  = async (newVendor) => {
  try{
  debugger;
  const response = await axiosInstance.post(`${baseUrlVendorManage}vendors/transactions`, newVendor );
  return response;
 } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};
export const getAllVendorTransiction = async (dates) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}vendors/transactions/all?startDate=${dates.startDate}&endDate=${dates.endDate}`);
    return response;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};
export const getAllClientIds = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}clients/transactions/clientIds`);
    return response;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    throw error;
  }
};
export const getClientforpoints = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}clients/transactions/clientIds/balances`);
    return response;
  } catch (error) {
    console.error('Error retrieving clients:', error);
    return error;
  }
};
export const getClientDatabyClientId = async (clientId) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}clients/transactions/finance?clientId=${clientId}`);
    return response;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};

export const downloadVendorExcel = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}vendors/transactions/all/excel`, {
      responseType: 'blob', // Set the response type to 'blob' to receive binary data
    });

    if (response.status === 200) {
      // Extract file name from the content-disposition header
      const contentDispositionHeader = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'downloaded_file.xlsx';

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const urlObject = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = fileName; // Set the extracted file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(urlObject);
    } else {
      console.error('Error downloading file. Response status:', response.status);
      throw new Error('Download failed');
    }
  } catch (error) {
    console.error('Error downloading file', error);
    throw new Error('Download failed');
  }
};
export const downloadPaymentExcel = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}vendors/transactions/all/excel`, {
      responseType: 'blob', // Set the response type to 'blob' to receive binary data
    });

    if (response.status === 200) {
      // Extract file name from the content-disposition header
      const contentDispositionHeader = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'downloaded_file.xlsx';

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const urlObject = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = fileName; // Set the extracted file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(urlObject);
    } else {
      console.error('Error downloading file. Response status:', response.status);
      throw new Error('Download failed');
    }
  } catch (error) {
    console.error('Error downloading file', error);
    throw new Error('Download failed');
  }
};
export const downloadClientExcel = async (clientId) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}clients/transactions/finance/excel?clientId=${clientId}`, {
      responseType: 'blob', // Set the response type to 'blob' to receive binary data
    });

    if (response.status === 200) {
      // Extract file name from the content-disposition header
      const contentDispositionHeader = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'downloaded_file.xlsx';

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const urlObject = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = fileName; // Set the extracted file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(urlObject);
    } else {
      console.error('Error downloading file. Response status:', response.status);
      throw new Error('Download failed');
    }
  } catch (error) {
    console.error('Error downloading file', error);
    throw new Error('Download failed');
  }
};

export const UpdateClientPoints  = async (Pointsallocation) => {
  try{
  debugger;
  const response = await axiosInstance.put(`${baseUrlVendorManage}clients/points`, Pointsallocation );
  return response;
 } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};
export const submitClientDetails = async (clientId, dateFrom, dateTo) => {
  try {
    // Construct the query parameters
    let url = `${baseUrlVendorManage}clients/transactions/finance?clientId=${clientId}`;

    // Add dateFrom and dateTo to the query string if they are provided
    if (dateFrom) {
      url += `&startDate=${encodeURIComponent(dateFrom)}`;
    }
    if (dateTo) {
      url += `&endDate=${encodeURIComponent(dateTo)}`;
    }

    // Make the API call with the constructed URL
    const response = await axiosInstance.get(url);
    console.log("djbdhbas0",response);
    return response;
  } catch (error) {
    console.error('Error retrieving client data:', error);
    throw error;
  }
};
export const getAllVendorTransictionbyVendorId = async (VendorId , vendorServiceId) => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}vendors/transactions/usage/${VendorId}/${vendorServiceId}`);
    return response;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};
export const getAllVendorServiceId = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}vendors/services/all`);
    return response;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};

export const UpdatedVendorPoints  = async (newVendor) => {
  try{
  debugger;
  const response = await axiosInstance.post(`${baseUrlVendorManage}trezi/services/update-points`, newVendor );
  return response;
 } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};
export const getAllPointsinPool = async () => {
  try {
    debugger;
    const response = await axiosInstance.get(`${baseUrlVendorManage}clients/treziPoolPoints`);
    return response;
  } catch (error) {
    console.error('Error retrieving attributes:', error);
    return error.response;
  }
};